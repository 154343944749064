
.ng2-tag-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
}

.ng2-tag-input__text-input {
    background-color: transparent;
}
