@import 'paper-dashboard/variables';

/**  Modals  **/

.modal-header {
    padding: 0.5rem 0.5rem 0 1rem;
}

.modal-header .close {
    outline: none !important;
}

.modal-content {
    animation-name: example;
    animation-duration: 0.3s;
}

@keyframes example {
    0% {
        transform: scale(0.5)
    }
    75% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1)
    }
}


/**  Dropdowns  **/

.no-arrow .dropdown-toggle::after {
    content: unset;
}

.dropdown-menu {

    .dropdown-item {
        padding-right: 0.5em;
    }

    .dropdown-item.checked {
        background-color: $placeholder-gray;
    }

    .dropdown-item:hover {
        background-color: darken($placeholder-gray, 20%) !important;
    }
}
