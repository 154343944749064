@import 'paper-dashboard/variables';

/**  Used in Select dropdowns  **/
.bootstrap-select .dropdown-menu.inner {
  background-color: #F3F3F3;
}

.btn-sm {
  padding: 5px 8px;
}
